body {
    font-family: 'Work Sans', sans-serif !important;
    background-color: #FFF;
    color: #404040 !important;
    margin: 0;
    padding: 0;
}

/* fix for selects in modals */
#menu-, #menu-role {
    z-index: 1400 !important;
  }
